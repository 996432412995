html, body {
  height: 100%;
}

body {
  overflow: hidden;
}

body > * {
  display: none;
}

#banner-ie {
  display: block;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  padding: 20px;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;

  color: #444;
  background-color: white;

  .display {
    max-width: 690px;
    padding: 1.5em;
    margin: 0 auto;
  }

  .img-holder {
    text-align: center;
    margin-bottom: 1em;
  }

  img {
    width: 80px;
    height: 80px;
  }

  p {
    margin: 0 0 1em 0;
    line-height: 1.4;
    color: red;
  }

  ul {
    clear: both;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 0;
    margin: 0 0 0 1em;
    color: #333;
    margin-bottom: .5em;
    line-height: 1.1;
  }
}
